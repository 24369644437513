import React, { type HTMLAttributes, type ReactElement } from 'react';
import { isElement } from 'react-is';
import { Link } from 'gatsby';

// Components
import { Column } from '../Grid';
import { ArrowRight, Calendar, Download, Email, Launch } from '@carbon/react/icons';

// Styles
import cx from 'clsx';
import * as css from './MiniCard.module.scss';

const ICONS = {
    arrowRight: <ArrowRight size={20} aria-label="Open resource" />,
    download: <Download size={20} aria-label="Download" />,
    email: <Email size={20} aria-label="Email" />,
    calendar: <Calendar size={20} aria-label="Calendar" />,
    default: <Launch size={20} aria-label="Open resource" />,
} as const;
type ActionIcon = keyof typeof ICONS;

export interface MiniCardProps {
    title: NonNullable<ReactNode>;
    children?: NonNullable<ReactNode>;

    href?: string;
    actionIcon: ActionIcon | ReactElement;

    className?: string;
    linkProps?: HTMLAttributes<HTMLAnchorElement>;
}

export default function MiniCard({ children, href, title, actionIcon, className, linkProps, ...rest }: MiniCardProps) {
    let iconElement: ReactElement;
    if (isElement(actionIcon)) iconElement = actionIcon;
    else if (typeof actionIcon === 'string' && Object.hasOwn(ICONS, actionIcon)) iconElement = ICONS[actionIcon];
    else iconElement = ICONS.default;

    const cardContent = (
        <div className={cx(className, css.card)}>
            <div className={css.wrapper}>
                <div className={css.title} children={title} />
                {children == null ? (
                    <div className={css.icon} children={iconElement} />
                ) : (
                    <div className={css.image} children={children} />
                )}
            </div>
        </div>
    );

    const cardContainer =
        href != null && href.charAt(0) === '/' ? (
            <Link to={href} className="cds--tile--clickable" {...linkProps} children={cardContent} />
        ) : (
            // eslint-disable-next-line gatsby/use-gatsby-link
            <a href={href} className="cds--tile--clickable" {...linkProps} children={cardContent} />
        );

    return <Column colMd={4} colLg={4} noGutterSm {...rest} children={cardContainer} />;
}
