import React from 'react';
import { Row } from '../Grid';

import cn from 'clsx';
import * as css from './CardGroup.module.scss';

export interface CardGroupProps {
    children: React.ReactNode;
    className?: string;
    style?: CSSProperties;
}

export default function CardGroup(props: CardGroupProps) {
    const { children, className, ...rest } = props;
    return <Row {...rest} className={cn(css.cardGroup, className)} children={children} />;
}
