import React, { type AnchorHTMLAttributes } from 'react';
import { Link as CarbonLink } from '@carbon/react';

import cn from 'clsx';
import * as css from './Link.module.scss';

export type LinkProps = AnchorHTMLAttributes<HTMLAnchorElement>;

export default function Link({ className, ...rest }: LinkProps) {
    return <CarbonLink {...rest} className={cn(css.link, className)} />;
}
