import React from 'react';
import { Row, Column } from '../Grid';

import cn from 'clsx';
import * as styles from './ExpressiveList.module.scss';

export interface ExpressiveListProps {
    children: ReactNode;
    pictogram: ReactNode;

    title: NonNullable<ReactNode>;
    titleType: 'expressive-04' | 'expressive-02';

    className?: string;
}

export default function ExpressiveList(props: ExpressiveListProps) {
    const { children, className, pictogram, title, titleType } = props;

    const ExpressiveListTitleClassNames = cn(styles.title, {
        [styles.expressive_04]: titleType === 'expressive-04',
        [styles.expressive_02]: titleType === 'expressive-02',
    });

    if (!pictogram) {
        return (
            <Row className={cn(styles.listRow, className)}>
                <Column colMd={2} colLg={4} noGutterLgLeft>
                    <h3 className={ExpressiveListTitleClassNames} children={title} />
                </Column>
                <Column colMd={6} colLg={8}>
                    <p className={styles.content} children={children} />
                </Column>
            </Row>
        );
    }
    return (
        <Row className={cn(styles.listRow, className)}>
            <Column colMd={2} colLg={1} noGutterLgLeft children={pictogram} />
            <Column colMd={6} colLg={7} className={styles.pictogramContent}>
                <h3 className={ExpressiveListTitleClassNames} children={title} />
                <p className={styles.content} children={children} />
            </Column>
        </Row>
    );
}
